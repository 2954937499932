import * as fromNotifications from './notifications.reducer';
import * as fromSubscriptions from './subscriptions.reducer';
import * as fromGripSubscriptions from './grip-subscriptions.reducer';
import * as fromGripLocations from './grip-locations.reducer';
import * as fromManualSubscriptions from './manual-subscriptions.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'Notifications';

export interface NotificationState {
  notifications: fromNotifications.NotificationsState;
  subscriptions: fromSubscriptions.SubscriptionsState;
  gripSubscriptions: fromGripSubscriptions.GripSubscriptionsState;
  manualSubscriptions: fromManualSubscriptions.ManualSubscriptionsState;
  gripLocations: fromGripLocations.GripLocationsState;
}

export const reducers: ActionReducerMap<NotificationState> = {
  notifications: fromNotifications.NotificationsReducer,
  subscriptions: fromSubscriptions.SubscriptionsReducer,
  gripSubscriptions: fromGripSubscriptions.GripSubscriptionsReducer,
  manualSubscriptions: fromManualSubscriptions.ManualSubscriptionsReducer,
  gripLocations: fromGripLocations.GripLocationsReducer,
};

export const getNotificationState = createFeatureSelector<NotificationState>(featureKey);

export const metaReducers: MetaReducer<NotificationState>[] = !environment.production ? [] : [];
