import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromGripSubscriptions from '../reducers/grip-subscriptions.reducer';
import * as fromRoot from 'src/app/store';

export const getGripSubscriptionState = createSelector(
  fromFeature.getNotificationState,
  (state: fromFeature.NotificationState) => state.gripSubscriptions
);

export const getGripSubscriptionEntities = createSelector(getGripSubscriptionState, fromGripSubscriptions.getGripSubscriptionsEntities);

export const getAllGripSubscriptions = createSelector(getGripSubscriptionEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getFirstGripSubscription = createSelector(getAllGripSubscriptions, subs => {
  return subs?.[0] || undefined;
});

export const getGripSubscriptionsLoaded = createSelector(getGripSubscriptionState, fromGripSubscriptions.getGripSubscriptionsLoaded);
export const getGripSubscriptionsLoading = createSelector(getGripSubscriptionState, fromGripSubscriptions.getGripSubscriptionsLoading);
