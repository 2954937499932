import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/subscriptions.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { SubscriptionsService } from '../../services/subscriptions.service';

export const LoadSubscriptions$ = createEffect(
  (actions$ = inject(Actions), service = inject(SubscriptionsService)) => {
    return actions$.pipe(
      ofType(actions.LoadSubscriptions),
      switchMap(({ token }) =>
        service.getSubscriptions(token).pipe(
          map(subscriptions => actions.LoadSubscriptionsSuccess({ subscriptions })),
          catchError(error => of(actions.LoadSubscriptionsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const SaveSubscription$ = createEffect(
  (actions$ = inject(Actions), service = inject(SubscriptionsService)) => {
    return actions$.pipe(
      ofType(actions.SaveSubscription),
      switchMap(({ subscription, koad }) =>
        service.saveSubscription(subscription, koad).pipe(
          map(subscription => actions.SaveSubscriptionSuccess({ subscription })),
          catchError(error => of(actions.SaveSubscriptionFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const SaveSubscriptions$ = createEffect(
  (actions$ = inject(Actions), service = inject(SubscriptionsService)) => {
    return actions$.pipe(
      ofType(actions.SaveSubscriptions),
      switchMap(({ subscriptions, koad, token }) =>
        service.saveSubscriptions(subscriptions, koad, token).pipe(
          map(subscriptions => actions.SaveSubscriptionsSuccess({ subscriptions })),
          catchError(error => of(actions.SaveSubscriptionsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const DeleteSubscription$ = createEffect(
  (actions$ = inject(Actions), service = inject(SubscriptionsService)) => {
    return actions$.pipe(
      ofType(actions.DeleteSubscription),
      switchMap(({ token, id }) =>
        service.deleteSubscription(id, token).pipe(
          map(subscription => actions.DeleteSubscriptionSuccess({ subscription })),
          catchError(error => of(actions.DeleteSubscriptionFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const DeleteSubscriptions$ = createEffect(
  (actions$ = inject(Actions), service = inject(SubscriptionsService)) => {
    return actions$.pipe(
      ofType(actions.DeleteSubscriptions),
      switchMap(({ token }) =>
        service.deleteSubscriptions(token).pipe(
          map(subscription => actions.DeleteSubscriptionsSuccess()),
          catchError(error => of(actions.DeleteSubscriptionFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
