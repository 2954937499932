import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/grip-locations.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { GripLocation } from '../../models';

export interface GripLocationsState {
  entities: { [id: number]: GripLocation };
  nearest: GripLocation | null;
  loaded: boolean;
  loading: boolean;
  nearestLoaded: boolean;
  nearestLoading: boolean;
  error?: any;
}

export const initialState: GripLocationsState = {
  entities: {},
  nearest: null,
  loaded: false,
  loading: false,
  nearestLoaded: false,
  nearestLoading: false,
};

export const GripLocationsReducer = createReducer(
  initialState,
  on(actions.LoadGripLocations, (state: GripLocationsState) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),
  on(actions.LoadGripLocationsSuccess, (state: GripLocationsState, { Locations }) => {
    const entities = ToEntities(Locations, 'id', {});
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.LoadGripLocationsFail, (state: GripLocationsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(actions.LoadNearestGripLocation, (state: GripLocationsState) => {
    return {
      ...state,
      nearestLoaded: false,
      nearestLoading: true,
    };
  }),
  on(actions.LoadNearestGripLocationSuccess, (state: GripLocationsState, { location }) => {
    return {
      ...state,
      nearest: location,
      nearestLoading: false,
      nearestLoaded: true,
    };
  }),
  on(actions.LoadNearestGripLocationFail, (state: GripLocationsState, { error }) => {
    return {
      ...state,
      nearestLoading: false,
      nearestLoaded: false,
      error,
    };
  }),
  on(actions.ResetGripLocations, (state: GripLocationsState) => {
    return {
      ...initialState,
    };
  }),
  on(actions.ResetNearestGripLocation, (state: GripLocationsState) => {
    return {
      ...state,
      nearest: null,
      nearestLoaded: false,
      nearestLoading: false,
    };
  })
);

export const getGripLocationsEntities = (state: GripLocationsState) => state.entities;
export const getNearestGripLocation = (state: GripLocationsState) => state.nearest;
export const getGripLocationsLoading = (state: GripLocationsState) => state.loading;
export const getGripLocationsLoaded = (state: GripLocationsState) => state.loaded;
export const getNearestGripLocationLoading = (state: GripLocationsState) => state.nearestLoading;
export const getNearestGripLocationLoaded = (state: GripLocationsState) => state.nearestLoaded;
export const getGripLocationError = (state: GripLocationsState) => state.error;
