import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/manual-subscriptions.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { ManualSubscription } from '../../models';

export interface ManualSubscriptionsState {
  entities: { [id: number]: ManualSubscription };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: ManualSubscriptionsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const ManualSubscriptionsReducer = createReducer(
  initialState,
  on(actions.LoadManualSubscriptions, actions.SaveManualSubscription, (state: ManualSubscriptionsState) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),
  on(actions.LoadManualSubscriptionsSuccess, (state: ManualSubscriptionsState, { subscriptions }) => {
    const entities = ToEntities(subscriptions, 'id', {});
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.SaveManualSubscriptionSuccess, (state: ManualSubscriptionsState, { subscription }) => {
    const entities = {
      ...state.entities,
      [subscription.id]: subscription,
    };
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(
    actions.LoadManualSubscriptionsFail,
    actions.DeleteManualSubscriptionFail,
    actions.SaveManualSubscriptionFail,
    (state: ManualSubscriptionsState, { error }) => {
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    }
  ),
  on(actions.DeleteManualSubscriptionSuccess, (state: ManualSubscriptionsState, { subscription }) => {
    const { [subscription.id]: removed, ...entities } = state.entities;

    return {
      ...state,
      loading: false,
      loaded: true,
      entities,
    };
  }),
  on(actions.DeleteManualSubscriptionsSuccess, (state: ManualSubscriptionsState) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      entities: {},
    };
  })
);

export const getManualSubscriptionsEntities = (state: ManualSubscriptionsState) => state.entities;
export const getManualSubscriptionsLoading = (state: ManualSubscriptionsState) => state.loading;
export const getManualSubscriptionsLoaded = (state: ManualSubscriptionsState) => state.loaded;
export const getManualSubscriptionError = (state: ManualSubscriptionsState) => state.error;
