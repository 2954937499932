import * as SubscriptionsEffects from './subscriptions.effect';
import * as NotificationsEffects from './notifications.effect';
import * as GripSubscriptionsEffects from './grip-subscriptions.effect';
import * as GripLocationsEffects from './grip-locations.effect';
import * as ManualSubscriptionsEffects from './manual-subscriptions.effect';

export const effects = {
  ...NotificationsEffects,
  ...SubscriptionsEffects,
  ...GripSubscriptionsEffects,
  ...GripLocationsEffects,
  ...ManualSubscriptionsEffects,
};
