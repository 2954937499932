import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/grip-subscriptions.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { GripSubscriptionsService } from '../../services';

import * as gripLocationActions from '../actions/grip-locations.action';

export const LoadGripSubscriptions$ = createEffect(
  (actions$ = inject(Actions), service = inject(GripSubscriptionsService)) => {
    return actions$.pipe(
      ofType(actions.LoadGripSubscriptions),
      switchMap(({ token }) =>
        service.getSubscriptions(token).pipe(
          map(subscriptions => actions.LoadGripSubscriptionsSuccess({ subscriptions })),
          catchError(error => of(actions.LoadGripSubscriptionsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const SaveGripSubscriptions$ = createEffect(
  (actions$ = inject(Actions), service = inject(GripSubscriptionsService)) => {
    return actions$.pipe(
      ofType(actions.SaveGripSubscription),
      switchMap(({ subscription, token }) =>
        service.saveSubscription(subscription, token).pipe(
          map(subscription => actions.SaveGripSubscriptionSuccess({ subscription })),
          catchError(error => of(actions.SaveGripSubscriptionFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const DeleteGripSubscriptions$ = createEffect(
  (actions$ = inject(Actions), service = inject(GripSubscriptionsService)) => {
    return actions$.pipe(
      ofType(actions.DeleteGripSubscriptions),
      switchMap(({ token }) =>
        service.deleteSubscriptions(token).pipe(
          map(subscription => actions.DeleteGripSubscriptionsSuccess()),
          catchError(error => of(actions.DeleteGripSubscriptionFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const SaveGripSubscriptionSuccess$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(actions.SaveGripSubscriptionSuccess, actions.DeleteGripSubscriptionsSuccess),
      map(({}) => gripLocationActions.ResetNearestGripLocation())
    );
  },
  { functional: true }
);
