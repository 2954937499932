import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/grip-locations.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { GripLocationsService } from '../../services';
import * as addressActions from '../../../addresses/store/actions/address.action';

export const LoadGripLocations$ = createEffect(
  (actions$ = inject(Actions), service = inject(GripLocationsService)) => {
    return actions$.pipe(
      ofType(actions.LoadGripLocations),
      switchMap(({ municipalityId }) =>
        service.getLocations(municipalityId).pipe(
          map(Locations => actions.LoadGripLocationsSuccess({ Locations })),
          catchError(error => of(actions.LoadGripLocationsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadNearestGripLocation$ = createEffect(
  (actions$ = inject(Actions), service = inject(GripLocationsService)) => {
    return actions$.pipe(
      ofType(actions.LoadNearestGripLocation),
      switchMap(({ postalCode, houseNumber, addition, token }) =>
        service.getNearestLocation(postalCode, houseNumber, addition, token).pipe(
          map(location => actions.LoadNearestGripLocationSuccess({ location })),
          catchError(error => of(actions.LoadNearestGripLocationFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadNearestGripLocationFail$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(actions.LoadNearestGripLocationFail),
      switchMap(({ error }) => {
        if (error.error?.code == "ADDRESS_INVALID") {
           return of(addressActions.ClearAddress({ redirect: true }));
        }
        return of();
      })
    );
  },
  { functional: true }
);
