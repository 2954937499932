import { createAction, props } from '@ngrx/store';
import { Subscription } from '../../models';

export const LoadSubscriptions = createAction('[Notifications] Load Subscriptions', props<{ token: string }>());
export const LoadSubscriptionsSuccess = createAction(
  '[Notifications] Load Subscriptions success',
  props<{ subscriptions: Subscription[] }>()
);
export const LoadSubscriptionsFail = createAction('[Notifications] Load Subscriptions Fail', props<{ error: any }>());

export const SaveSubscriptions = createAction(
  '[Notifications] Save Subscriptions',
  props<{ subscriptions: Subscription[]; koad: string, token: string }>()
);
export const SaveSubscriptionsSuccess = createAction(
  '[Notifications] Save Subscriptions success',
  props<{ subscriptions: Subscription[] }>()
);
export const SaveSubscriptionsFail = createAction('[Notifications] Save Subscriptions Fail', props<{ error: any }>());

export const SaveSubscription = createAction('[Notifications] Save Subscription', props<{ subscription: Subscription; koad: string }>());
export const SaveSubscriptionSuccess = createAction('[Notifications] Save Subscription success', props<{ subscription: Subscription }>());
export const SaveSubscriptionFail = createAction('[Notifications] Save Subscription Fail', props<{ error: any }>());

export const DeleteSubscription = createAction('[Notifications] Delete Subscription', props<{ token: string; id: number }>());
export const DeleteSubscriptionSuccess = createAction(
  '[Notifications] Delete Subscription success',
  props<{ subscription: Subscription }>()
);
export const DeleteSubscriptionFail = createAction('[Notifications] Delete Subscriptions Fail', props<{ error: any }>());

export const DeleteSubscriptions = createAction('[Notifications] Delete Subscriptions', props<{ token: string }>());
export const DeleteSubscriptionsSuccess = createAction('[Notifications] Delete Subscriptions success');
export const DeleteSubscriptionsFail = createAction('[Notifications] Delete Subscriptions Fail', props<{ error: any }>());
