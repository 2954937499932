import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromManualSubscriptions from '../reducers/manual-subscriptions.reducer';
import * as fromRoot from 'src/app/store';

export const getManualSubscriptionState = createSelector(
  fromFeature.getNotificationState,
  (state: fromFeature.NotificationState) => state.manualSubscriptions
);

export const getManualSubscriptionEntities = createSelector(
  getManualSubscriptionState,
  fromManualSubscriptions.getManualSubscriptionsEntities
);

export const getAllManualSubscriptions = createSelector(getManualSubscriptionEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getFirstManualSubscription = createSelector(getAllManualSubscriptions, subs => {
  return subs?.[0] || undefined;
});

export const getManualSubscriptionsLoaded = createSelector(
  getManualSubscriptionState,
  fromManualSubscriptions.getManualSubscriptionsLoaded
);
export const getManualSubscriptionsLoading = createSelector(
  getManualSubscriptionState,
  fromManualSubscriptions.getManualSubscriptionsLoading
);
