import { createAction, props } from '@ngrx/store';
import { ManualSubscription } from '../../models';

export const LoadManualSubscriptions = createAction('[Notifications] Load Manual Subscriptions', props<{ token: string }>());
export const LoadManualSubscriptionsSuccess = createAction(
  '[Notifications] Load Manual Subscriptions success',
  props<{ subscriptions: ManualSubscription[] }>()
);
export const LoadManualSubscriptionsFail = createAction('[Notifications] Load Manual Subscriptions Fail', props<{ error: any }>());

export const SaveManualSubscription = createAction('[Notifications] Save ManualSubscription', props<{ koad: string; token: string }>());
export const SaveManualSubscriptionSuccess = createAction(
  '[Notifications] Save Manual Subscription success',
  props<{ subscription: ManualSubscription }>()
);
export const SaveManualSubscriptionFail = createAction('[Notifications] Save Manual Subscription Fail', props<{ error: any }>());

export const DeleteManualSubscription = createAction('[Notifications] Delete Manual Subscription', props<{ token: string; id: number }>());
export const DeleteManualSubscriptionSuccess = createAction(
  '[Notifications] Delete Manual Subscription success',
  props<{ subscription: ManualSubscription }>()
);
export const DeleteManualSubscriptionFail = createAction('[Notifications] Delete Manual Subscriptions Fail', props<{ error: any }>());

export const DeleteManualSubscriptions = createAction('[Notifications] Delete Manual Subscriptions', props<{ token: string }>());
export const DeleteManualSubscriptionsSuccess = createAction('[Notifications] Delete Manual Subscriptions success');
export const DeleteManualSubscriptionsFail = createAction('[Notifications] Delete Manual Subscriptions Fail', props<{ error: any }>());
