import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromProducts from '../reducers/notifications.reducer';
import * as fromRoot from 'src/app/store';

export const getNotificationsState = createSelector(
  fromFeature.getNotificationState,
  (state: fromFeature.NotificationState) => state.notifications
);

export const getNotificationPermissionsGranted = createSelector(getNotificationsState, fromProducts.getNotificationPermissionsGranted);
export const getNotificationPermissionsChecked = createSelector(getNotificationsState, fromProducts.getNotificationPermissionsChecked);
export const getNotificationPermissionsPending = createSelector(getNotificationsState, fromProducts.getNotificationPermissionsPending);
export const getNotificationPermissionsError = createSelector(getNotificationsState, fromProducts.getNotificationPermissionsError);
export const getNotificationToken = createSelector(getNotificationsState, fromProducts.getNotificationToken);
