import { createAction, props } from '@ngrx/store';
import { GripLocation } from '../../models';

export const LoadGripLocations = createAction('[Notifications] Load Grip Locations', props<{ municipalityId: number }>());
export const LoadGripLocationsSuccess = createAction('[Notifications] Load Grip Locations success', props<{ Locations: GripLocation[] }>());
export const LoadGripLocationsFail = createAction('[Notifications] Load Grip Locations Fail', props<{ error: any }>());

export const LoadNearestGripLocation = createAction(
  '[Notifications] Load Nearest Grip Location',
  props<{ postalCode: string; houseNumber: number; addition?: string; token?: string }>()
);
export const LoadNearestGripLocationSuccess = createAction(
  '[Notifications] Load Nearest Grip Location success',
  props<{ location: GripLocation }>()
);
export const LoadNearestGripLocationFail = createAction('[Notifications] Load Nearest Grip Location Fail', props<{ error: any }>());

export const ResetGripLocations = createAction('[Notifications] Reset Grip Locations');
export const ResetNearestGripLocation = createAction('[Notifications] Reset Nearest Grip Location');
