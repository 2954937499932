import { createAction, props } from '@ngrx/store';
import { GripSubscription } from '../../models';

export const LoadGripSubscriptions = createAction('[Notifications] Load Grip Subscriptions', props<{ token: string }>());
export const LoadGripSubscriptionsSuccess = createAction(
  '[Notifications] Load Grip Subscriptions success',
  props<{ subscriptions: GripSubscription[] }>()
);
export const LoadGripSubscriptionsFail = createAction('[Notifications] Load Grip Subscriptions Fail', props<{ error: any }>());

export const SaveGripSubscription = createAction(
  '[Notifications] Save GripSubscription',
  props<{ subscription: GripSubscription; token: string }>()
);
export const SaveGripSubscriptionSuccess = createAction(
  '[Notifications] Save Grip Subscription success',
  props<{ subscription: GripSubscription }>()
);
export const SaveGripSubscriptionFail = createAction('[Notifications] Save Grip Subscription Fail', props<{ error: any }>());

export const DeleteGripSubscription = createAction('[Notifications] Delete Grip Subscription', props<{ token: string; id: number }>());
export const DeleteGripSubscriptionSuccess = createAction(
  '[Notifications] Delete Grip Subscription success',
  props<{ subscription: GripSubscription }>()
);
export const DeleteGripSubscriptionFail = createAction('[Notifications] Delete Grip Subscriptions Fail', props<{ error: any }>());

export const DeleteGripSubscriptions = createAction('[Notifications] Delete Grip Subscriptions', props<{ token: string }>());
export const DeleteGripSubscriptionsSuccess = createAction('[Notifications] Delete Grip Subscriptions success');
export const DeleteGripSubscriptionsFail = createAction('[Notifications] Delete Grip Subscriptions Fail', props<{ error: any }>());
