import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/grip-subscriptions.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { GripSubscription } from '../../models';

export interface GripSubscriptionsState {
  entities: { [id: number]: GripSubscription };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: GripSubscriptionsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const GripSubscriptionsReducer = createReducer(
  initialState,
  on(actions.LoadGripSubscriptions, actions.SaveGripSubscription, (state: GripSubscriptionsState) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),
  on(actions.LoadGripSubscriptionsSuccess, (state: GripSubscriptionsState, { subscriptions }) => {
    const entities = ToEntities(subscriptions, 'id', {});
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.SaveGripSubscriptionSuccess, (state: GripSubscriptionsState, { subscription }) => {
    const entities = {
      ...state.entities,
      [subscription.id]: subscription,
    };
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(
    actions.LoadGripSubscriptionsFail,
    actions.DeleteGripSubscriptionFail,
    actions.SaveGripSubscriptionFail,
    (state: GripSubscriptionsState, { error }) => {
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    }
  ),
  on(actions.DeleteGripSubscriptionSuccess, (state: GripSubscriptionsState, { subscription }) => {
    const { [subscription.id]: removed, ...entities } = state.entities;

    return {
      ...state,
      loading: false,
      loaded: true,
      entities,
    };
  }),
  on(actions.DeleteGripSubscriptionsSuccess, (state: GripSubscriptionsState) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      entities: {},
    };
  })
);

export const getGripSubscriptionsEntities = (state: GripSubscriptionsState) => state.entities;
export const getGripSubscriptionsLoading = (state: GripSubscriptionsState) => state.loading;
export const getGripSubscriptionsLoaded = (state: GripSubscriptionsState) => state.loaded;
export const getGripSubscriptionError = (state: GripSubscriptionsState) => state.error;
