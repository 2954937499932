import { createAction, props } from '@ngrx/store';

export const RequestPermissions = createAction('[Notifications] Request permissions');
export const RequestPermissionsSuccess = createAction('[Notifications] Request permissions Success', props<{ token: string }>());
export const RequestPermissionsFail = createAction('[Notifications] Request permissions Fail', props<{ error: any }>());

export const DisableNotifications = createAction('[Notifications] Disable Permissions');
export const DisableNotificationsSuccess = createAction('[Notifications] Disable Permissions Success');
export const DisableNotificationsFail = createAction('[Notifications] Disable Permissions Fail', props<{ error: any }>());

export const InitNotifications = createAction('[Notifications] Initialize Notifications');
export const InitNotificationsSuccess = createAction(
  '[Notifications] Initialize Notifications Success',
  props<{ permissionsGranted: boolean; token?: string }>()
);
export const InitNotificationsFail = createAction('[Notifications] Initialize Notifications Fail', props<{ error: any }>());
