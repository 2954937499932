import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromGripLocations from '../reducers/grip-locations.reducer';
import * as fromRoot from 'src/app/store';
import { WasteCalendarDate } from 'src/app/waste-calendar/models';

export const getGripLocationState = createSelector(
  fromFeature.getNotificationState,
  (state: fromFeature.NotificationState) => state.gripLocations
);

export const getGripLocationEntities = createSelector(getGripLocationState, fromGripLocations.getGripLocationsEntities);
export const getNearestGripLocation = createSelector(getGripLocationState, fromGripLocations.getNearestGripLocation);

export const getAllGripLocations = createSelector(getGripLocationEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getGripLocationsLoaded = createSelector(getGripLocationState, fromGripLocations.getGripLocationsLoaded);
export const getGripLocationsLoading = createSelector(getGripLocationState, fromGripLocations.getGripLocationsLoading);

export const getNearestGripLocationLoaded = createSelector(getGripLocationState, fromGripLocations.getNearestGripLocationLoaded);
export const getNearestGripLocationLoading = createSelector(getGripLocationState, fromGripLocations.getNearestGripLocationLoading);

export const getNearestGripLocationDate = createSelector(getNearestGripLocation, location => {
  if (!location) return null;
  return {
    id: 0,
    date: location.nextDate.date,
    gripLocation: location,
    wasteType: { id: 0, code: 'grip', icon: 'grip', title: 'GRIP-wagen' },
  } as WasteCalendarDate;
});
