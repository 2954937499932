import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/manual-subscriptions.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ManualSubscriptionsService } from '../../services';

export const LoadManualSubscriptions$ = createEffect(
  (actions$ = inject(Actions), service = inject(ManualSubscriptionsService)) => {
    return actions$.pipe(
      ofType(actions.LoadManualSubscriptions),
      switchMap(({ token }) =>
        service.getSubscriptions(token).pipe(
          map(subscriptions => actions.LoadManualSubscriptionsSuccess({ subscriptions })),
          catchError(error => of(actions.LoadManualSubscriptionsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const SaveManualSubscriptions$ = createEffect(
  (actions$ = inject(Actions), service = inject(ManualSubscriptionsService)) => {
    return actions$.pipe(
      ofType(actions.SaveManualSubscription),
      switchMap(({ koad, token }) =>
        service.saveSubscription(koad, token).pipe(
          map(subscription => actions.SaveManualSubscriptionSuccess({ subscription })),
          catchError(error => of(actions.SaveManualSubscriptionFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const DeleteManualSubscriptions$ = createEffect(
  (actions$ = inject(Actions), service = inject(ManualSubscriptionsService)) => {
    return actions$.pipe(
      ofType(actions.DeleteManualSubscriptions),
      switchMap(({ token }) =>
        service.deleteSubscriptions(token).pipe(
          map(subscription => actions.DeleteManualSubscriptionsSuccess()),
          catchError(error => of(actions.DeleteManualSubscriptionFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
