import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSubscriptions from '../reducers/subscriptions.reducer';
import * as fromRoot from 'src/app/store';

export const getSubscriptionState = createSelector(
  fromFeature.getNotificationState,
  (state: fromFeature.NotificationState) => state.subscriptions
);

export const getSubscriptionEntities = createSelector(getSubscriptionState, fromSubscriptions.getSubscriptionsEntities);

export const getAllSubscriptions = createSelector(getSubscriptionEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getSubscriptionsLoaded = createSelector(getSubscriptionState, fromSubscriptions.getSubscriptionsLoaded);
export const getSubscriptionsLoading = createSelector(getSubscriptionState, fromSubscriptions.getSubscriptionsLoading);
