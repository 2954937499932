import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { from, of } from 'rxjs';
import * as actions from '../actions/notifications.action';
import * as subscriptionActions from '../actions/subscriptions.action';
import * as gripSubscriptionActions from '../actions/grip-subscriptions.action';
import * as manualSubscriptionActions from '../actions/manual-subscriptions.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { NotificationsService } from '../../services';
import { SubscriptionsService } from '../../services/subscriptions.service';
import { Store } from '@ngrx/store';

export const RequestPermissions$ = createEffect(
  (actions$ = inject(Actions), service = inject(NotificationsService)) => {
    return actions$.pipe(
      ofType(actions.RequestPermissions),
      switchMap(() =>
        from(service.requestPermissions()).pipe(
          map(token => actions.RequestPermissionsSuccess({ token })),
          catchError(error => of(actions.RequestPermissionsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const DisableNotifications$ = createEffect(
  (actions$ = inject(Actions), service = inject(NotificationsService)) => {
    return actions$.pipe(
      ofType(actions.DisableNotifications),
      switchMap(() =>
        from(service.disableNotifcations()).pipe(
          map(() => actions.DisableNotificationsSuccess()),
          catchError(error => of(actions.DisableNotificationsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const InitNotifications$ = createEffect(
  (actions$ = inject(Actions), service = inject(NotificationsService)) => {
    return actions$.pipe(
      ofType(actions.InitNotifications),
      switchMap(() =>
        from(service.checkPermissions()).pipe(
          map(data => actions.InitNotificationsSuccess({ ...data })),
          catchError(error => of(actions.InitNotificationsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const InitNotificationsSuccess$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(actions.InitNotificationsSuccess),
      map(({ token }) => subscriptionActions.LoadSubscriptions({ token: token || '' }))
    );
  },
  { functional: true }
);

export const InitNotificationsSuccessGrip$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(actions.InitNotificationsSuccess),
      map(({ token }) => gripSubscriptionActions.LoadGripSubscriptions({ token: token || '' }))
    );
  },
  { functional: true }
);

export const InitNotificationsSuccessManual$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(actions.InitNotificationsSuccess),
      map(({ token }) => manualSubscriptionActions.LoadManualSubscriptions({ token: token || '' }))
    );
  },
  { functional: true }
);

export const RequestPermissionsSuccess$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(actions.RequestPermissionsSuccess),
      map(({ token }) => subscriptionActions.LoadSubscriptions({ token: token || '' }))
    );
  },
  { functional: true }
);
