import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GripLocation } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GripLocationsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getLocations(municipalityId: number) {
    return this.http.get<GripLocation[]>(`${this.BASE_URL}api/my-rova/notifications/grip/Locations?municipalityId=${municipalityId}`);
  }

  getNearestLocation(postalCode: string, houseNumber: number, addition: string = '', token: string = '') {
    return this.http.get<GripLocation>(
      `${this.BASE_URL}api/my-rova/grip/location?postalCode=${postalCode}&housenumber=${houseNumber}&addition=${
        addition || ''
      }&token=${token}`
    );
  }
}
