import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ManualSubscription } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ManualSubscriptionsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getSubscriptions(token: string) {
    return this.http.get<ManualSubscription[]>(`${this.BASE_URL}api/my-rova/notifications/manual/subscriptions?token=${token}`);
  }

  saveSubscription(koad: string, token: string) {
    return this.http.post<ManualSubscription>(
      `${this.BASE_URL}api/my-rova/notifications/manual/subscriptions?token=${token}&koad=${koad}`,
      null
    );
  }

  deleteSubscription(id: number, token: string) {
    return this.http.delete<ManualSubscription>(`${this.BASE_URL}api/my-rova/notifications/manual/subscriptions/${id}?token=${token}`);
  }

  deleteSubscriptions(token: string) {
    return this.http.delete<any>(`${this.BASE_URL}api/my-rova/notifications/manual/subscriptions?token=${token}`);
  }
}
