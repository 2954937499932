import { createReducer, on } from '@ngrx/store';
import * as pageActions from '../actions/subscriptions.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { Subscription } from '../../models';

export interface SubscriptionsState {
  entities: { [id: number]: Subscription };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: SubscriptionsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const SubscriptionsReducer = createReducer(
  initialState,
  on(pageActions.LoadSubscriptions, pageActions.SaveSubscriptions, (state: SubscriptionsState) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),
  on(pageActions.LoadSubscriptionsSuccess, pageActions.SaveSubscriptionsSuccess, (state: SubscriptionsState, { subscriptions }) => {
    const entities = ToEntities(subscriptions, 'id', {});
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.SaveSubscriptionSuccess, (state: SubscriptionsState, { subscription }) => {
    const entities = {
      ...state.entities,
      [subscription.id]: subscription,
    };
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(
    pageActions.LoadSubscriptionsFail,
    pageActions.DeleteSubscriptionFail,
    pageActions.SaveSubscriptionFail,
    (state: SubscriptionsState, { error }) => {
      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      };
    }
  ),
  on(pageActions.DeleteSubscriptionSuccess, (state: SubscriptionsState, { subscription }) => {
    const { [subscription.id]: removed, ...entities } = state.entities;

    return {
      ...state,
      loading: false,
      loaded: true,
      entities,
    };
  }),
  on(pageActions.DeleteSubscriptionsSuccess, (state: SubscriptionsState) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      entities: {},
    };
  })
);

export const getSubscriptionsEntities = (state: SubscriptionsState) => state.entities;
export const getSubscriptionsLoading = (state: SubscriptionsState) => state.loading;
export const getSubscriptionsLoaded = (state: SubscriptionsState) => state.loaded;
export const getSubscriptionError = (state: SubscriptionsState) => state.error;
