import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FirebaseMessaging, GetTokenOptions } from '@capacitor-firebase/messaging';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { GripSubscription, Subscription } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GripSubscriptionsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getSubscriptions(token: string) {
    return this.http.get<GripSubscription[]>(`${this.BASE_URL}api/my-rova/notifications/grip/subscriptions?token=${token}`);
  }

  saveSubscription(subscription: GripSubscription, token: string) {
    return this.http.post<GripSubscription>(`${this.BASE_URL}api/my-rova/notifications/grip/subscriptions?token=${token}`, subscription);
  }

  deleteSubscription(id: number, token: string) {
    return this.http.delete<GripSubscription>(`${this.BASE_URL}api/my-rova/notifications/grip/subscriptions/${id}?token=${token}`);
  }

  deleteSubscriptions(token: string) {
    return this.http.delete<any>(`${this.BASE_URL}api/my-rova/notifications/grip/subscriptions?token=${token}`);
  }
}
