import { createReducer, on } from '@ngrx/store';
import * as actions from '../actions/notifications.action';

export interface NotificationsState {
  token: string;
  permissionsGranted: boolean;
  permissionsChecked: boolean;
  permissionsPending: boolean;
  error?: any;
}

export const initialState: NotificationsState = {
  token: '',
  permissionsChecked: false,
  permissionsGranted: false,
  permissionsPending: false,
};

export const NotificationsReducer = createReducer(
  initialState,
  on(actions.RequestPermissions, (state: NotificationsState) => {
    return {
      ...state,
      permissionsPending: true,
    };
  }),
  on(actions.RequestPermissionsSuccess, (state: NotificationsState, { token }) => {
    return {
      ...state,
      permissionsPending: false,
      permissionsChecked: true,
      permissionsGranted: true,
      token,
      error: undefined,
    };
  }),
  on(actions.RequestPermissionsFail, (state: NotificationsState, { error }) => {
    return {
      ...state,
      permissionsPending: false,
      permissionsChecked: true,
      permissionsGranted: false,
      error,
    };
  }),
  on(actions.DisableNotifications, (state: NotificationsState) => {
    return {
      ...state,
      permissionsPending: true,
    };
  }),
  on(actions.DisableNotificationsSuccess, (state: NotificationsState) => {
    return {
      ...state,
      permissionsPending: false,
      permissionsChecked: true,
      permissionsGranted: false,
      token: '',
      error: undefined,
    };
  }),
  on(actions.DisableNotificationsFail, (state: NotificationsState, { error }) => {
    return {
      ...state,
      permissionsPending: false,
      permissionsChecked: true,
      error,
    };
  }),
  on(actions.InitNotifications, (state: NotificationsState) => {
    return {
      ...state,
      permissionsPending: true,
      permissionsChecked: false,
      permissionsGranted: false,
    };
  }),
  on(actions.InitNotificationsSuccess, (state: NotificationsState, { permissionsGranted, token }) => {
    return {
      ...state,
      permissionsPending: false,
      permissionsChecked: true,
      permissionsGranted,
      token: token ?? '',
      error: undefined,
    };
  }),
  on(actions.InitNotificationsFail, (state: NotificationsState, { error }) => {
    return {
      ...state,
      permissionsPending: false,
      permissionsChecked: true,
      permissionsGranted: false,
      error,
    };
  })
);

export const getNotificationPermissionsGranted = (state: NotificationsState) => state.permissionsGranted;
export const getNotificationPermissionsChecked = (state: NotificationsState) => state.permissionsChecked;
export const getNotificationPermissionsPending = (state: NotificationsState) => state.permissionsPending;
export const getNotificationPermissionsError = (state: NotificationsState) => state.error;
export const getNotificationToken = (state: NotificationsState) => state.token;
