import { Injectable, Optional, Inject, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FirebaseMessaging, GetTokenOptions } from '@capacitor-firebase/messaging';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { SwPush } from '@angular/service-worker';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public platform = inject(Platform);

  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string,
    private swPush: SwPush
  ) {}

  public async requestPermissions(): Promise<string> {
    const result = await FirebaseMessaging.requestPermissions();
    localStorage.removeItem('mijn-rova:notifications-disabled');
    const token = await this.getToken();

    return token;
  }

  public async disableNotifcations(): Promise<void> {
    const result = await FirebaseMessaging.deleteToken();
    localStorage.setItem('mijn-rova:notifications-disabled', JSON.stringify(true));
  }

  public async checkPermissions(): Promise<{ permissionsGranted: boolean; token?: string }> {
    if (JSON.parse(localStorage.getItem('mijn-rova:notifications-disabled') || 'false')) {
      return { permissionsGranted: false };
    }
    if (!this.swPush.isEnabled && !Capacitor.isNativePlatform()) {
      return { permissionsGranted: false };
    }
    if (Capacitor.getPlatform() === 'web' && this.platform.IOS) {
      return { permissionsGranted: false };
    }

    const result = await FirebaseMessaging.checkPermissions();

    if (result.receive === 'granted') {
      return { permissionsGranted: true, token: await this.getToken() };
    }
    return { permissionsGranted: false };
  }

  private async getToken(): Promise<string> {
    const options: GetTokenOptions = {
      vapidKey: environment.firebase.vapidKey,
    };
    if (Capacitor.getPlatform() === 'web') {
      const registration = await navigator.serviceWorker.ready;
      options.serviceWorkerRegistration = registration;
    }
    const { token } = await FirebaseMessaging.getToken(options);
    return token;
  }
}
