import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FirebaseMessaging, GetTokenOptions } from '@capacitor-firebase/messaging';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { Subscription } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getSubscriptions(token: string) {
    return this.http.get<Subscription[]>(`${this.BASE_URL}api/my-rova/notifications/subscriptions?token=${token}`);
  }

  saveSubscription(subscription: Subscription, koad: string) {
    return this.http.post<Subscription>(`${this.BASE_URL}api/my-rova/notifications/subscription?koad=${koad}`, subscription);
  }

  saveSubscriptions(subscriptions: Subscription[], koad: string, token: string) {
    return this.http.post<Subscription[]>(`${this.BASE_URL}api/my-rova/notifications/subscriptions?koad=${koad}&token=${token}`, subscriptions);
  }

  deleteSubscription(id: number, token: string) {
    return this.http.delete<Subscription>(`${this.BASE_URL}api/my-rova/notifications/subscriptions/${id}?token=${token}`);
  }

  deleteSubscriptions(token: string) {
    return this.http.delete<any>(`${this.BASE_URL}api/my-rova/notifications/subscriptions?token=${token}`);
  }
}
